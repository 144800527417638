import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";

const AddCity = (props) => {
  const [city, addCity] = useState({});
  const [mapState, setMapState] = useState([0, 0]);
  const [center, setCenter] = useState([0, 0]);
  const [located, setLocated] = useState(false);
  const [mapData, setMapData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  let map;

  const stateName = (e) => {
    console.log(e);
    const ud = Object.assign({}, city);
    ud.country_id = e.target.value;
    addCity(ud);
  };

  const cityName = (e) => {
    console.log(e);
    const ud = Object.assign({}, city);
    ud.name = e.target.value;
    addCity(ud);
  };

  const latLongFunc = async (e) => {
    const ud = Object.assign({}, city);
    ud.latitude = e[0];
    ud.longitude = e[1];
    await addCity(ud);
  };

  const image = (e) => {
    const ud = Object.assign({}, city);
    ud.image_url = e.target.files[0];
    addCity(ud);
  };

  function MyComponent() {
    // useEffect(()=>{
    //     map.locate();
    // },[])
    map = useMapEvents({
      // whenReady: () => {
      //     console.log('loaded')
      //     locationCenter();
      // },
      click: async (e) => {
        let i = await map.mouseEventToLatLng(e.originalEvent);
        await setMapState([Number(i.lat), Number(i.lng)]);
        console.log(map);
        await latLongFunc([Number(i.lat), Number(i.lng)]);

        // await latitude(i.lat);
        //  latitude(i.lat).then(()=>longitude(i.lng));
        // setTimeout(()=>latitude(i.lat), 105);
        // console.log('shop data inside click', shopData)
        // map.locate();
      },
      locationfound: async (location) => {
        console.log(location);
        console.log("location found:", [
          location.latlng.lat,
          location.latlng.lng,
        ]);
        //
        // if (mapState[0] === 0 && mapState[1] === 0) {
        //     setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
        //     setLocated(true);
        //     await map.flyTo([Number(location.latlng.lat), Number(location.latlng.lng, {
        //         animate: false
        //     })]);
        //     await setCenter([Number(location.latlng.lat), Number(location.latlng.lng)]);
        //
        // }

        if (mapData.longitude === 0 && mapData.latitude === 0) {
          latLongFunc([location.latlng.lat, location.latlng.lng]);
          await map.panTo([
            Number(mapData?.latitude),
            Number(mapData?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(mapData?.latitude),
            Number(mapData?.longitude),
          ]);
        }

        if (mapData != "") {
          // console.log('po hin te shopi', ([Number(shopData?.latitude), Number(location?.longitude)]));
          // console.log('centraaaa', [Number(shopData?.latitude), Number(location?.longitude)]);
          // using flyTo instead of panTo, it has some animation in it
          await map.panTo([
            Number(mapData?.latitude),
            Number(mapData?.longitude, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(mapData?.latitude),
            Number(mapData?.longitude),
          ]);
        } else {
          // console.log('po hin te location defauklt')
          // using flyTo instead of panTo, it has some animation in it
          await map.panTo([
            Number(location.latlng.lat),
            Number(location.latlng.lng, {
              animate: false,
            }),
          ]);
          await setCenter([
            Number(location.latlng.lat),
            Number(location.latlng.lng),
          ]);
        }
        // );
        // setCenter([Number(location.latlng.lat), Number(location.latlng.lng)])
        setMapState([Number(location.latlng.lat), Number(location.latlng.lng)]);
        setLocated(true);
        map.stopLocate();
        // console.log(mapState)
      },
    });
    if (located == false) {
      map.locate();
    }
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fd = new FormData();

    fd.append("country_id", city.country_id);
    fd.append("image", city.image_url);
    fd.append("name", city.name);
    fd.append("longitude", city.longitude);
    fd.append("latitude", city.latitude);
    if(city["latitude"] === undefined || city["longitude"] === undefined){
      toast.error('Please select city location in map')
  }else if(!submitting){
    setSubmitting(true);
    axios
      .post("cities", fd)
      .then((data) => {
        // console.log(data);
        // console.log("heheheheeeeeeeeeeeee");
        toast.success("City has been added successfully!");
        window.history.back();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      }).finally(() => {
        setSubmitting(false);
      })
    }
  };

  return (
    <div className="editcities p-5 text-left">
      <div className="row">
        <h1>Add City</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row mb-5 ">
          <div className="col-md-12">
            <MapContainer center={center} zoom={13} scrollWheelZoom={true}>
              <MyComponent />
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
                {mapData != "" ? (
                  <Marker position={[mapData.latitude, mapData.longitude]}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                ) : mapState != "" ? (
                  <Marker position={[mapState[0], mapState[1]]}>
                    <Popup>
                      Pointer. <br /> Pointer.
                    </Popup>
                  </Marker>
                ) : (
                  ""
                )}
            </MapContainer>
          </div>
        </div>
        <ToastContainer />
        <input type="file" onChange={image} name="image" />
        {/* {
          city["longitude"]!==undefined?
        <div className={'row pl-3'}><p className="font-weight-bold pr-1">Lat:</p>{city["latitude"]}<p className="font-weight-bold pl-3 pr-1">  Long:</p>{city["longitude"] }</div>:
        ""} */}
        <select onChange={stateName}>
          <option value={null} disabled selected>
            Selekto
          </option>
          <option value={1}>Kosove</option>
        </select>
        <input
          type="text"
          placeholder="City Name"
          required
          title="This field is mandatory"
          onChange={cityName}
        ></input>
         {
          city["longitude"]!==undefined?
          <>
        <input
          type="text"
          step=".0001"
          className="col-12 input__button"
          value={city["latitude"] ?? " "}
          placeholder="Latitude"
          // onChange={latitude}
          // disabled={!isShopEdit}
        />
        <input
          type="text"
          step=".0001"
          className="col-12 input__button"
          value={city["longitude"] ?? " "}
          placeholder="Longitude"
          // onChange={longitude}
          // disabled={!isShopEdit}
        />
          </>:""}
        <button type="submit" disabled={submitting} className="buttonAction">Add City</button>
      </form>
    </div>
  );
};
export default AddCity;
